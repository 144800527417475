import React, { useState } from "react";
import axios from "axios";
import Protein from './Protein.js';

function App() {
  const [query, setQuery] = useState("");
  const [searchType, setSearchType] = useState("gene"); // Default to gene search
  const [results, setResults] = useState(null);
  // const [protein, showProtein] = useState(null);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    try {
      setResults(null);
      setError(null);

      // Fetch data from the Flask API with query and search type parameters
      const response = await axios.get(`https://bsc.tail8ddf.ts.net/api/gene`, {
        params: { query, search_type: searchType },
      });
      setResults(response.data);
      // showProtein?
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Gene and Protein Search</h1>
      {/* <Protein pdbId={protein} /> ??? */}
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Enter Gene or Protein Name"
        style={{ padding: "10px", width: "200px", marginRight: "10px" }}
      />
      <select onChange={(e) => setSearchType(e.target.value)} value={searchType}>
        <option value="gene">Search by Gene Name</option>
        <option value="protein">Search by Protein Name</option>
      </select>
      <button onClick={handleSearch} style={{ padding: "10px 20px", marginLeft: "10px" }}>
        Search
      </button>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {results && (
    <div style={{ marginTop: "20px", textAlign: "left" }}>
        <h2>Results for "{query}"</h2>
        {searchType === "gene" ? (
            Object.entries(results.gene_data).map(([section, content], index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                    <h3>{section}</h3>
                    <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                        {content}
                    </pre>
                </div>
            ))
        ) : (
            results.matches.map((match, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                    <h3>Gene: {match.gene_name}</h3>
                    <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                        {match.protein_data}
                    </pre>
                </div>
            ))
        )}
    </div>
)}
    </div>
  );
}

export default App;
