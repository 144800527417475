// works
import React, { useEffect, useRef } from 'react';
import { PluginContext } from 'molstar/lib/mol-plugin/context';
import { DefaultPluginSpec } from 'molstar/lib/mol-plugin/spec';

const App = ({ geneName }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    if (!viewerRef.current) return;

    // Initialize the Molstar plugin directly without `createPluginUI`
    const plugin = new PluginContext(DefaultPluginSpec());
    plugin.init();
    plugin.mount(viewerRef.current);

    const url = 'http://localhost:3000/' + geneName + '.pdb'; // Use PDB format here

    async function loadStructure() {
      try {
        
        // Download and visualize the 4hhb protein as a PDB file
        const data = await plugin.builders.data.download({
          url: url,
          // url: 'https://files.rcsb.org/download/4hhb.pdb', // Use PDB format here
          isBinary: false,
        });

        const trajectory = await plugin.builders.structure.parseTrajectory(data, 'pdb'); // Specify 'pdb' format
        await plugin.builders.structure.hierarchy.applyPreset(trajectory, 'default');
      } catch (error) {
        console.error('Error loading structure:', error);
      }
    }

    loadStructure();
  }, []);

  return (
    <div
      ref={viewerRef}
      style={{ width: '100vw', height: '100vh' }}
    />
  );
};

export default App;











//import React, { useEffect, useRef } from 'react';
//import { PluginContext } from 'molstar/lib/mol-plugin/context';
//import { DefaultPluginSpec } from 'molstar/lib/mol-plugin/spec';
//
//const App = () => {
//  const viewerRef = useRef(null);
//
//  useEffect(() => {
//    if (!viewerRef.current) return;
//
//    // Initialize the Molstar plugin directly without `createPluginUI`
//    const plugin = new PluginContext(DefaultPluginSpec());
//    plugin.init();
//    plugin.mount(viewerRef.current);
//
//    async function loadStructure() {
//      try {
//        // Download and visualize the 4hhb protein
//        const data = await plugin.builders.data.download({
//          url: 'https://files.rcsb.org/download/4hhb.cif',
//          isBinary: false,
//        });
//
//        const trajectory = await plugin.builders.structure.parseTrajectory(data, 'mmcif');
//        await plugin.builders.structure.hierarchy.applyPreset(trajectory, 'default');
//      } catch (error) {
//        console.error('Error loading structure:', error);
//      }
//    }
//
//    loadStructure();
//  }, []);
//
//  return (
//    <div
//      ref={viewerRef}
//      style={{ width: '100vw', height: '100vh' }}
//    />
//  );
//};
//
//export default App;
//
